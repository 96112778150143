import { useCallback, useEffect, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import { areVideoAdsPlayingAtom } from "@sunrise/ads";
import type { Icons } from "@sunrise/icons";
import {
  actionPlayerToggleFullscreen,
  playerAtom,
  selectPlayerIsPlaying,
  selectPlayerShouldBeFullscreen,
} from "@sunrise/player";
import {
  usePlayerVolume,
  useTogglePlayPause,
} from "@sunrise/yallo-player-controls";

const isTyping = () =>
  ["INPUT", "TEXTAREA"].includes(document.activeElement?.tagName ?? "");

export type PlayerFeedback = {
  level?: number;
  updatedAt: number;
  icon: Icons;
};

export function useKeyboardPlayerControls() {
  const [feedback, setFeedback] = useState<PlayerFeedback | undefined>();
  const dispatchPlayer = useSetAtom(playerAtom);
  const { isMuted, toggleMute, increaseVolume, decreaseVolume } =
    usePlayerVolume();
  const isPlaying = useAtomValue(selectPlayerIsPlaying);
  const { toggle: togglePlayPause } = useTogglePlayPause();
  const toggleFullscreen = useCallback(() => {
    dispatchPlayer(actionPlayerToggleFullscreen());
  }, [dispatchPlayer]);

  const isPlayingAds = useAtomValue(areVideoAdsPlayingAtom);
  const isFullscreen = useAtomValue(selectPlayerShouldBeFullscreen);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (isTyping()) return;
      if (event.metaKey || event.shiftKey || event.altKey) return;

      let update: PlayerFeedback = {
        icon: "play",
        updatedAt: new Date().getTime(),
      };

      switch (event.code) {
        case "Space":
          if (isPlayingAds) return;
          togglePlayPause();
          update = {
            ...update,
            icon: isPlaying ? "pause" : "play",
          };
          break;
        case "ArrowUp":
          update = {
            ...update,
            level: increaseVolume(),
            icon: "volumeUp",
          };
          break;
        case "ArrowDown": {
          const level = decreaseVolume();
          update = {
            ...update,
            level,
            icon: level === 0 ? "volumeOff" : "volumeDown",
          };
          break;
        }
        case "KeyM":
          toggleMute();
          update = {
            ...update,
            icon: isMuted ? "volumeUp" : "volumeOff",
          };
          break;
        case "KeyF":
          toggleFullscreen();
          update = {
            ...update,
            icon: isFullscreen ? "fullscreenExit" : "fullscreen",
          };
          break;
        default: {
          return;
        }
      }

      event.preventDefault();

      setFeedback({
        ...update,
      });
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [
    decreaseVolume,
    increaseVolume,
    isFullscreen,
    isMuted,
    isPlaying,
    isPlayingAds,
    toggleFullscreen,
    toggleMute,
    togglePlayPause,
  ]);

  return feedback;
}
