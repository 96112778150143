import { type ReactNode, Suspense } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai";

import {
  areVideoAdsPausedAtom,
  resumeAds,
  selectNativeSkipControlState,
  videoAdsUIAtom,
} from "@sunrise/ads";
import { selectPlayerShouldBeFullscreen } from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { Button, Text } from "@sunrise/yallo-web-components";

import { VolumeControl } from "../players/overlay/volume-control";
import styles from "./ads.module.css";
import { SkipButton } from "./skip-button";
import { UpsellButton } from "./upsell-button";

export function Ads(): ReactNode {
  const ui = useAtomValue(videoAdsUIAtom);
  const t = useTranslator();
  const isPaused = useAtomValue(areVideoAdsPausedAtom);
  const nativeSkipControlState = useAtomValue(selectNativeSkipControlState);
  const isFullscreen = useAtomValue(selectPlayerShouldBeFullscreen);

  if (!ui) {
    return null;
  }

  const counter = ui.counter ? (
    <Text data-testid={`ads.counter`} size="small" variant="label">
      {t(ui.counter.key, ui.counter.args)}
    </Text>
  ) : null;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {ui.why?.visible && ui.message ? (
          <Text size="small" variant="label">
            {t(ui.message)}
          </Text>
        ) : null}
      </div>
      <div
        className={clsx([
          styles.bottom,
          {
            [styles.skipControlCountdown]:
              !isFullscreen && nativeSkipControlState === "countdown",
            [styles.skipControlVisible]:
              !isFullscreen && nativeSkipControlState === "skip-control",
          },
        ])}
      >
        {isPaused && (
          <Button
            className={styles.playButton}
            icon="play"
            variant="text"
            hideLabel
            onClick={resumeAds}
          >
            {t("button_play_pause")}
          </Button>
        )}
        <div className={styles.counter}>
          <VolumeControl />
          {counter}
        </div>
        <div className={styles.actions}>
          <Suspense>
            <UpsellButton />
          </Suspense>

          <SkipButton />
        </div>
      </div>
    </div>
  );
}
