import { getAnalytics } from "firebase/analytics";

/**
 * A bit of a hacky way to configure analytics but it seems to be the only way to do it with firebase.
 * The function setDefaultEventParameters is exposed but simply doesn't work.
 */
export function configureAnalytics(properties: Record<string, unknown>) {
  if ("gtag" in window && typeof window.gtag === "function") {
    const measurementId = getAnalytics()?.app.options.measurementId;
    if (!measurementId) {
      return;
    }

    try {
      window.gtag("config", measurementId, properties);
    } catch {
      // If the gtag function is not available, we don't need to do anything.
    }
  }
}
