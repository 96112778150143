import {
  lazy,
  type ReactElement,
  Suspense,
  useCallback,
  useEffect,
} from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";
import { useRoute } from "wouter";

import { areVideoAdsPlayingAtom } from "@sunrise/ads";
import { errorToShowInPlayerAtom } from "@sunrise/error-dialog";
import {
  actionPlayerToggleFullscreen,
  getVideoElement,
  playerAtom,
  selectPlayerShouldBeFullscreen,
} from "@sunrise/player";
import { selectPlayerStatsEnabled } from "@sunrise/player-stats";
import { useTranslator } from "@sunrise/translator";
import { useIdle } from "@sunrise/utils";
import {
  PLAYER_AUTO_HIDE_CONTROLS_TIMEOUT_IN_MS,
  useSeekbar,
} from "@sunrise/yallo-player-controls";
import { Link, VisuallyHidden } from "@sunrise/yallo-web-components";

import { rawRoute, route } from "../../config/route";
import { Ads } from "../ads/ads";
import { SkipFfwdAdsButton } from "../ads/skip-ffwd-ads-button";
import { PlayerControls, PlayerInfo, PlayerStats } from "./overlay";

const PlayerErrorOverlay = lazy(() => import("./overlay/player-error-overlay"));

import { PauseAds } from "@/features/ads/pause-ads";

import { PlayerKeyboardControls } from "./overlay/player-keyboard-controls";
import styles from "./player-ui.module.css";

function PlayerUI(): ReactElement | null {
  const t = useTranslator();
  const idle = useIdle(
    PLAYER_AUTO_HIDE_CONTROLS_TIMEOUT_IN_MS,
    getVideoElement().parentElement,
  );
  const playerStatsEnabled = useAtomValue(selectPlayerStatsEnabled);
  const portalContainer = document.getElementById("player-controls-portal");
  const dispatchPlayer = useSetAtom(playerAtom);
  const [isTvPage] = useRoute(rawRoute.tv);
  const { isSeeking } = useSeekbar();
  const canHidePlayerControls = idle && !isSeeking;
  const isFullscreen = useAtomValue(selectPlayerShouldBeFullscreen);
  const toggleFullscreen = useCallback(() => {
    dispatchPlayer(actionPlayerToggleFullscreen());
  }, [dispatchPlayer]);
  const isPlayingAds = useAtomValue(areVideoAdsPlayingAtom);

  if (!portalContainer) {
    throw new Error("#player-controls-portal is not defined");
  }

  useEffect(() => {
    if (!isFullscreen) {
      return;
    }
    document.documentElement.classList.toggle(
      "player-fullscreen-idle",
      canHidePlayerControls,
    );
    return () =>
      document.documentElement.classList.remove("player-fullscreen-idle");
  }, [isFullscreen, canHidePlayerControls]);

  useEffect(() => {
    if (!isTvPage && isFullscreen) toggleFullscreen();
  }, [isTvPage, isFullscreen, toggleFullscreen]);

  return (
    <Suspense>
      {createPortal(
        isTvPage ? (
          <>
            {isPlayingAds ? (
              <div className={styles.wrapper}>
                <Ads />
              </div>
            ) : (
              <PlayerUIOrError canHidePlayerControls={canHidePlayerControls} />
            )}
            <PlayerKeyboardControls />
          </>
        ) : (
          <div className={styles.wrapper}>
            <Link
              className={styles.returnToPlayer}
              href={route.tv.root()}
              variant="none"
            >
              <VisuallyHidden>
                {t("player_mini_return_to_full_player")}
              </VisuallyHidden>
            </Link>
          </div>
        ),
        portalContainer,
      )}
      {playerStatsEnabled && <PlayerStats />}
    </Suspense>
  );
}

export { PlayerUI };

function PlayerUIOrError({
  canHidePlayerControls,
}: {
  canHidePlayerControls: boolean;
}): ReactElement {
  const { error, hidden } = useAtomValue(errorToShowInPlayerAtom);

  const showPlayerUi = !error || hidden;

  if (!showPlayerUi) {
    return (
      <div className={styles.wrapper}>
        <PlayerErrorOverlay />
      </div>
    );
  }

  return (
    <>
      <div
        className={clsx(styles.wrapper, {
          [styles.visible]: !canHidePlayerControls,
        })}
      >
        <div
          className={clsx(styles.ui, {
            [styles.visible]: !canHidePlayerControls,
          })}
        >
          <PlayerInfo className={styles.playerInfo} />
          <SkipFfwdAdsButton className={styles.skipFfwdAdsButton} />
          <PlayerControls className={styles.controls} />
        </div>
        <PauseAds />
      </div>
      <PlayerInfo className={styles.mobilePlayerInfo} />
    </>
  );
}
