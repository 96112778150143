import type { ComponentProps } from "react";

import { useTranslator } from "@sunrise/translator";
import { RecordingDeleteWarning as RecordingDeleteWarningBase } from "@sunrise/yallo-web-components";

type RecordingDeleteWarningProps = Pick<
  ComponentProps<typeof RecordingDeleteWarningBase>,
  "expiresIn"
> &
  Pick<CommonProps, "className">;

export const RecordingDeleteWarning = ({
  expiresIn,
}: RecordingDeleteWarningProps) => {
  const t = useTranslator();

  return (
    <RecordingDeleteWarningBase
      expiresIn={expiresIn}
      label={
        (expiresIn ?? 0) > 1
          ? t("recordings_deleted_days", [expiresIn])
          : t("recordings_deleted_day", [expiresIn])
      }
    />
  );
};
