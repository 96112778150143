import { useContext, useEffect } from "react";

import type { Page } from "@sunrise/backend-types";

import { PageContext } from "./page-context";

export const useSetActivePage = (page: Page) => {
  const context = useContext(PageContext);

  const { showPage } = context || {};

  useEffect(() => {
    if (!showPage) return;

    const cleanup = showPage(page);

    return () => {
      cleanup();
    };
  }, [page, showPage]);
};
