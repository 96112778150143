import { unwrap } from "jotai/utils";
import { atomEffect } from "jotai-effect";

import { setDefaultProperties } from "@sunrise/firebase";
import { appVersionAtom, buildDetailsAtom } from "@sunrise/yallo-settings";
import { deviceIdAtom } from "@sunrise/yallo-websocket";

/**
 * Sets some basic properties that are the same for all events.
 * They hardly ever change so we sort of change them all together.
 *
 * This is emitted as a default property. So it is known for all future events.
 */
export const emitGlobalsEffect = atomEffect((get) => {
  const appVersion = get.peek(appVersionAtom);

  // NOTE: This deviceId may end up being irrelevant for google analytics.
  //       They have their own internal mechanism I think. But it is nice to be able to link events to a specific device.
  //       Should we get a lot of errors reported on a specific device we can check the analytics for it.
  const deviceGetter = get(deviceIdAtom);
  const deviceId = deviceGetter ? get(unwrap(deviceGetter)) : undefined;
  const build = get(buildDetailsAtom);

  setDefaultProperties({
    client_version: appVersion || "none",
    device_id: deviceId,
    build_nr: build?.buildNumber || "none",
    build_date: build?.buildDate || "none",
    build_commit_hash: build?.commitHash || "none",
  });
});
