import type { NgSearchEpgResult } from "@sunrise/search";
import { useContinueWatchingProgress } from "@sunrise/yallo-continue-watching";

import { route } from "@/config/route";
import { usePlay } from "@/hooks";

import { SearchEpisodeOrProgramResult } from "./search-episode-or-program-result";

export function SearchEpgResult({ item }: { item: NgSearchEpgResult }) {
  const { play, icon, label } = usePlay({
    channelId: item.channelId,
    epgId: item.epgId,
    epgStart: item.epgStart,
    epgEnd: item.epgEnd,
  });

  const detailRoute = item.assetId
    ? route.details.root({
        epgId: item.epgId,
        assetId: item.assetId,
      })
    : undefined;

  const progress = useContinueWatchingProgress({
    epgId: item.epgId,
    start: new Date(item.epgStart),
    end: new Date(item.epgEnd),
  });

  return (
    <SearchEpisodeOrProgramResult
      detailRoute={detailRoute}
      item={item}
      play={play}
      playIcon={icon}
      playLabel={label}
      progress={progress}
    />
  );
}
