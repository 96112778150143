import type { ReactNode } from "react";

import { useTranslator } from "@sunrise/translator";
import type { GenericRecordingGroup } from "@sunrise/yallo-recordings";
import type { ProgramBoxProps } from "@sunrise/yallo-web-components";
import { ProgramBox } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";

export function RecordingItemGroup(
  props: {
    active?: boolean;
    blockInteractivity?: boolean;
  } & GenericRecordingGroup &
    Pick<ProgramBoxProps, "variant"> &
    Pick<CommonProps, "className">,
): ReactNode {
  const t = useTranslator();

  const url = props.blockInteractivity
    ? null
    : route.recordings.series({
        recordingGroupId: props.id,
      });

  // TODO: clean up the params so it's either for a group or a single recording.
  return (
    <ProgramBox
      active={props.active}
      airTime={null}
      channelLogo={null}
      channelName={null}
      className={props.className}
      coverImageUrl={props.posterUrl}
      endDate={null}
      formattedEnd={null}
      formattedStart={null}
      playIcon={null}
      recordingState={props.recordingStatus}
      redirectUrl={url}
      startDate={null}
      subtitle={
        props.episodeCount
          ? t(
              "recordings_episode_template",
              [props.episodeCount],
              props.episodeCount > 1,
            )
          : null
      }
      title={props.title}
      variant={props.variant}
      isDirectory
    />
  );
}
