import { type ReactNode, useCallback } from "react";
import { atom, useAtomValue } from "jotai";

import {
  selectPlayerCurrentPlayRequest,
  selectPlayerCurrentStream,
  selectPlayerState,
} from "@sunrise/player";
import {
  selectPlayerSimpleStats,
  selectPlayerStatsConfiguration,
} from "@sunrise/player-stats";
import { getStreamStats } from "@sunrise/yallo-stream";
import { Button } from "@sunrise/yallo-web-components";

import styles from "./player-stats.module.css";

// Small atom to get all the data in one go. Or nothing when stats are not available
const statsAndExtraInfoAtom = atom((get) => {
  const stats = get(selectPlayerSimpleStats);

  if (!stats) {
    return { stats, extra: null };
  }

  const config = get(selectPlayerStatsConfiguration);
  const playRequest = get(selectPlayerCurrentPlayRequest);
  const stream = get(selectPlayerCurrentStream);
  const state = get(selectPlayerState);

  return {
    extra: {
      playRequest,
      stream: getStreamStats(stream),
      state,
      config: config
        ? {
            ...config,
            drm: undefined,
          }
        : undefined,
    },
    stats,
  };
});

export function PlayerStats(): ReactNode {
  const { stats, extra } = useAtomValue(statsAndExtraInfoAtom);

  const handleCopy = useCallback(() => {
    void navigator.clipboard.writeText(
      JSON.stringify({ stats, extra }, null, 2),
    );
  }, [stats, extra]);

  if (!stats) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.statsWrapper}>
        <Button
          className={styles.copyButton}
          icon="copy"
          variant="none"
          hideLabel
          onClick={handleCopy}
        >
          Copy stats
        </Button>
        <pre>
          {JSON.stringify(stats, null, 2)}
          {"\n\n"}
          {JSON.stringify(extra, null, 2)}
        </pre>
      </div>
    </div>
  );
}
