import { type ReactNode } from "react";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useHydrateAtoms } from "jotai/utils";
import { queryClientAtom } from "jotai-tanstack-query";

import { BaseError } from "@sunrise/error";
import { PageContextProvider } from "@sunrise/pages";
import type { Store } from "@sunrise/store";
import { StoreProvider } from "@sunrise/store";
import { TranslationProvider } from "@sunrise/translator";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error): boolean {
        if (failureCount >= 2) {
          return false;
        }

        if (error instanceof BaseError) {
          return error.shouldRetry;
        }

        return true;
      },
    },
  },
});

const HydrateAtoms = () => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);

  return null;
};

export function AppProvider(props: {
  children: ReactNode;
  enableDevTools: boolean;
  storeInstance: Store;
}): ReactNode {
  return (
    <StoreProvider
      enableDevTools={props.enableDevTools}
      store={props.storeInstance}
    >
      <HydrateAtoms />
      <PageContextProvider>
        <QueryClientProvider client={queryClient}>
          {props.enableDevTools && <ReactQueryDevtools position="bottom" />}
          <TranslationProvider>{props.children}</TranslationProvider>
        </QueryClientProvider>
      </PageContextProvider>
    </StoreProvider>
  );
}
