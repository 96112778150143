import { useCallback } from "react";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { useMemoryRouter } from "@sunrise/routing";
import { useTranslator } from "@sunrise/translator";
import {
  currentChannelGroupAtom,
  preferredChannelGroupIdAtom,
  type SelectableChannelGroupItem,
  selectableChannelGroupsAtom,
} from "@sunrise/yallo-channel-group";
import { Button, SlideOut } from "@sunrise/yallo-web-components";

import styles from "./channel-group-picker.module.css";

export function ChannelGroupList() {
  const { navigate } = useMemoryRouter();
  const t = useTranslator();
  const channelGroups = useAtomValue(selectableChannelGroupsAtom);
  const onItemEnterPress = useAtomCallback(
    useCallback((_get, set, group: SelectableChannelGroupItem): void => {
      set(preferredChannelGroupIdAtom, group.id);
    }, []),
  );
  const current = useAtomValue(currentChannelGroupAtom);

  return (
    <div className={styles.channelGroupContainer}>
      <div className={styles.channelGroupList}>
        {channelGroups.map((item) => (
          <SlideOut.Radio
            key={item.id}
            checked={item.id === current?.id}
            labelPosition="end"
            name="channel-group"
            onChange={() => onItemEnterPress(item)}
          >
            {item.name}
          </SlideOut.Radio>
        ))}
      </div>
      <Button
        className={styles.channelGroupAddButton}
        onClick={() => navigate("channel-group-editor")}
      >
        {t("create_channel_list_button")}
      </Button>
    </div>
  );
}
