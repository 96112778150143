import { useEffect } from "react";
import { useSetAtom } from "jotai";

import { idTokenAtom, PROCESSED } from "@sunrise/auth";

/**
 * Will check the query params for an id_token and authenticate with it.
 * If no id_token is found or after we actually authenticated, we will set a piece of state to indicate that we are done authenticating.
 *
 * Then the autostart can happen or we can redirect the enduser.
 */
export function useAuthentication(): void {
  const setIdToken = useSetAtom(idTokenAtom);

  const searchParams = new URLSearchParams(window.location.hash.substring(1));
  const idToken = searchParams.get("id_token");

  useEffect(
    function authenticateWithAccessToken() {
      if (!idToken) {
        setIdToken(PROCESSED);
        return;
      }

      setIdToken(idToken);
    },
    [idToken, setIdToken],
  );
}
