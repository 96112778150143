import { Icon } from "@sunrise/icons";
import { Text } from "@sunrise/yallo-web-components";

import { useKeyboardPlayerControls } from "@/hooks/use-keyboard-player-controls";

import styles from "./player-keyboard-controls.module.css";

export function PlayerKeyboardControls() {
  const feedback = useKeyboardPlayerControls();

  if (!feedback) return;

  return (
    <div key={feedback.updatedAt} className={styles.indicator}>
      <Icon height={48} name={feedback.icon} width={48} />
      {!!feedback.level && (
        <Text size="large" variant="headline">
          {feedback.level}%
        </Text>
      )}
    </div>
  );
}
