import { AxiosError } from "axios";
import { type Setter } from "jotai";

import { BackendRecordingsError } from "@sunrise/backend-core";
import { errorAtom } from "@sunrise/error";

export const handleRecordingsError = (set: Setter) => (error: unknown) => {
  if (error instanceof AxiosError && typeof error.response?.data === "string") {
    set(
      errorAtom,
      new BackendRecordingsError("Failed to create recording schedule", {
        errorCode: error.response.data,
      }),
    );
    return;
  }
  if (error instanceof Error) {
    set(errorAtom, error);
  }
};
