import type { ReactElement, ReactNode } from "react";
import { createContext, useContext } from "react";
import type { WritableAtom } from "jotai";
import { useAtom } from "jotai";

import type { Translatable } from "@sunrise/i18n";

type Route<T> = {
  id: T;
  title?: Translatable;
  component: ReactElement;
};

type MemoryRouterType<T> = {
  routes: Route<T>[];
  currentRoute: Route<T> | null;
  navigate: (id: T | null) => void;
};

const RouterContext = createContext<MemoryRouterType<string> | undefined>(
  undefined,
);

export const useCreateMemoryRouter = <T extends string | null>(
  routes: Route<T>[],
  initialRouteId: T,
  atom: WritableAtom<T | null, [T | null], unknown>,
): MemoryRouterType<T> => {
  const [currentPage, setCurrentPage] = useAtom(atom);

  return {
    routes,
    currentRoute:
      routes.find((route) => route.id === (currentPage ?? initialRouteId)) ??
      routes[0],
    navigate: setCurrentPage,
  };
};

export const MemoryRouter = () => {
  const context = useContext(RouterContext);
  return context?.currentRoute ? context.currentRoute.component : null;
};

export const MemoryRouterProvider = <T extends string>({
  router,
  children,
}: {
  router: MemoryRouterType<T>;
  children: ReactNode;
}) => (
  // @ts-expect-error cannot infer the type of the context value correctly
  <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
);

export const useMemoryRouter = <T extends string>(): MemoryRouterType<T> => {
  const context = useContext(RouterContext);
  if (!context) {
    throw new Error(
      "useMemoryRouter must be used within a MemoryRouterProvider",
    );
  }
  // @ts-expect-error cannot infer the type of the context value correctly
  return context;
};
