import { memo, useCallback, useMemo } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai";

import type { ChannelId } from "@sunrise/backend-types-core";
import { playerCurrentEpgItemAtom } from "@sunrise/player";
import { nowAtom } from "@sunrise/time";
import { type Nullable } from "@sunrise/utils";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";
import type { GuideProgram as GuideProgramType } from "@sunrise/yallo-guide";
import { Link, Text } from "@sunrise/yallo-web-components";

import styles from "./guide-program.module.css";

type GuideProgramProps = {
  programWidth: number;
  programTitle: Nullable<string>;
  program: GuideProgramType;
  startInPx: number;
  onMouseEnter: (program: GuideProgramType, channelId: ChannelId) => void;
  href: string;
  channelId: Nullable<ChannelId>;
  durationInMinutes: number;
};

function GuideProgramInternal({
  programTitle,
  programWidth,
  program,
  channelId,
  startInPx,
  onMouseEnter,
  href,
  durationInMinutes,
}: GuideProgramProps) {
  const style = useMemo(
    () => ({
      left: `${startInPx}px`,
      width: `${programWidth}px`,
    }),
    [startInPx, programWidth],
  );
  const playingEpgItem = useAtomValue(playerCurrentEpgItemAtom);

  const now = useAtomValue(nowAtom);
  const isLive = programIsPlayingAtTime(program, now);
  const isActive = playingEpgItem?.id === program.id;
  const onMouseEnterFn = useCallback(() => {
    if (!channelId) {
      return;
    }
    onMouseEnter(program, channelId);
  }, [onMouseEnter, program, channelId]);

  if (!programTitle) return null;

  let label = programTitle;
  if (durationInMinutes <= 10) label = `${programTitle[0]}.`;
  if (durationInMinutes <= 5) label = "…";
  if (durationInMinutes <= 3) label = ".";

  return (
    <Link
      className={clsx([
        styles.container,
        isLive && styles.live,
        isActive && styles.active,
      ])}
      draggable="false"
      href={href}
      style={style}
      variant="filled"
      onMouseEnter={onMouseEnterFn}
    >
      <Text
        aria-label={programTitle}
        className={clsx([
          styles.label,
          durationInMinutes <= 10 && styles.shortProgram,
        ])}
        size={isActive ? "large" : "medium"}
        title={programTitle}
        variant={isActive ? "label" : "body"}
      >
        {label}
      </Text>
    </Link>
  );
}

export const GuideProgram = memo(GuideProgramInternal);
