import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";

import { useErrorDialog } from "@sunrise/error-dialog";

import { login } from "@/core";

import { ErrorDialogDescription } from "./error-dialog-description";

/**
 * This component is responsible to show the errors that are put in the atoms which need to be shown to the users.
 * These errors are not really blocking errors but they may cause a certain interaction to fail.
 *
 * Whatever triggered the error should have also cleaned up after itself.
 * For example, if playout failed because the user's tokens were expired or because refresh showed the user no longer exists,
 * it should have already logged out the user.
 *
 * The only cleanup this component does is to remove the error from the state.
 */
export function ErrorDialog(): undefined {
  useErrorDialog({
    getFocusKey: getCurrentFocusKey,
    getConfirmationDescription: (dialog, eventId, at) =>
      eventId
        ? {
            type: "component",
            component: (
              <ErrorDialogDescription
                at={at}
                description={dialog.description}
                eventId={eventId}
              />
            ),
          }
        : dialog.description,
    skipSentryCapture: !import.meta.env.VITE_SENTRY_DSN && import.meta.env.DEV,
    doLogin: login,
    shouldShowUpsellActions: true,
  });
}
