import type {
  ComponentProps,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";
import { Drawer } from "vaul";

import type { Nullable } from "@sunrise/utils";

import { Button } from "../button";
import { Title } from "../text";
import styles from "./slide-out.module.css";

const query = "(width <= 800px)";

type Props = PropsWithChildren<{
  title: Nullable<string>;
  titlePrefix?: ReactNode;
  trigger?: ReactNode;
  labelClose?: Nullable<string>;
}> &
  CommonProps &
  Pick<
    ComponentProps<typeof Drawer.Root>,
    "open" | "onOpenChange" | "dismissible"
  >;

const SlideOut = ({
  children,
  trigger,
  title,
  open,
  onOpenChange,
  titlePrefix,
  dismissible = true,
  labelClose = "close",
}: Props): ReactElement => {
  const shouldSlideFromBottom = useMediaQuery(query);

  const props = shouldSlideFromBottom
    ? { snapPoints: [1], fadeFromIndex: 0 }
    : {};

  return (
    <Drawer.Root
      key={shouldSlideFromBottom.toString()}
      direction={shouldSlideFromBottom ? "bottom" : "right"}
      dismissible={dismissible}
      open={open}
      onOpenChange={onOpenChange}
      {...props}
    >
      {trigger ? <Drawer.Trigger asChild>{trigger}</Drawer.Trigger> : null}
      <Drawer.Portal>
        <Drawer.Overlay className={styles.overlay} />
        <Drawer.Content className={styles.content}>
          <div className={styles.inner}>
            <div
              className={clsx(styles.header, {
                [styles.withPrefix]: !!titlePrefix,
              })}
            >
              <div className={styles.headerInner}>
                {titlePrefix ? (
                  <div className={styles.titlePrefix}>{titlePrefix}</div>
                ) : null}
                <Drawer.Title asChild>
                  <Title level="h2" size="medium" variant="title">
                    {title}
                  </Title>
                </Drawer.Title>
              </div>
              {dismissible && (
                <Drawer.Close asChild>
                  <Button icon="close" variant="text" hideLabel>
                    {labelClose}
                  </Button>
                </Drawer.Close>
              )}
            </div>
            {children}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export { SlideOut };
