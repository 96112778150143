import { setUserId as setUserIdNative } from "firebase/analytics";

import { firebaseAnalytics } from "./firebase-analytics";

export function setUserId(userId: string | null) {
  if (!firebaseAnalytics) {
    return;
  }

  setUserIdNative(firebaseAnalytics, userId);
}
