import { clsx } from "clsx";

import type { MappedDisplayType } from "@sunrise/yallo-recommendations";

import styles from "./program-box.skeleton.module.css";

export function ProgramBoxSkeleton({
  variant = "box",
}: {
  variant?: MappedDisplayType;
}) {
  if (variant === "small") {
    return (
      <div className={clsx(styles.skeleton, styles[variant])}>
        <div className={clsx("skeleton-item", styles.programCover)} />
      </div>
    );
  }

  return (
    <div className={clsx(styles.skeleton, styles[variant])}>
      <div className={clsx(styles.epgInformation)}>
        <div className={clsx("skeleton-item", styles.status)} />
        <div className={clsx("skeleton-item", styles.airTime)} />
      </div>
      <div className={clsx("skeleton-item", styles.programCover)} />
      <div className={clsx("skeleton-item", styles.title)} />
      {variant !== "portrait" && (
        <div className={styles.descriptionWrapper}>
          <div className={clsx("skeleton-item", styles.description)} />
          <div className={clsx("skeleton-item", styles.description)} />
        </div>
      )}
    </div>
  );
}
