import { clsx } from "clsx";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import {
  ffwdCurrentAdBreakAtom,
  skipFfwdAdButtonVisibleAtom,
} from "@sunrise/ads";
import { playerDateTimeConverterAtom } from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { getPlayerManager } from "@sunrise/yallo-common-player-manager";
import { Button } from "@sunrise/yallo-web-components";

import styles from "./ads.module.css";

export function SkipFfwdAdsButton({
  className,
}: Pick<CommonProps, "className">) {
  const skipAdBreakVisible = useAtomValue(skipFfwdAdButtonVisibleAtom);
  const t = useTranslator();

  const skipBreak = useAtomCallback((get) => {
    async function trigger(): Promise<void> {
      const currentBreak = get(ffwdCurrentAdBreakAtom);
      const converter = get(playerDateTimeConverterAtom);
      if (!converter || !currentBreak) {
        return;
      }

      // NOTE: Seeking past the end of an ad break will trigger ad playout after the seek has happened.
      await getPlayerManager().seekToInCurrentPlayRequest(
        converter.fromDate(currentBreak.end),
      );
    }

    void trigger();
  });

  if (!skipAdBreakVisible) return null;

  return (
    <Button
      className={clsx(styles.button, className)}
      variant="none"
      onClick={skipBreak}
    >
      {t("ads_ffwd_skip")}
    </Button>
  );
}
