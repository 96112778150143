import type { Getter } from "jotai";
import { atom } from "jotai";
import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import { ngRecordingsApiAtom } from "@sunrise/backend-ng-recordings";
import type { RecordingId } from "@sunrise/backend-types-core";
import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";
import { flushRecordingsState } from "@sunrise/yallo-recordings";

import type { RecordingActionButtonResult } from "./types";

/**
 * The delete action for epg recordings is the exact same as the delete action for recordings recordings.
 *
 * When the status is planned vs recorded we will ask a different confirmation and we will have to show the button a bit differently.
 */
export const deleteRecordingAtom = (
  recordingId: RecordingId,
  status: "planned" | "recorded",
  get: Getter,
): RecordingActionButtonResult => {
  const mutationAtom = atomWithMutation((get) => {
    const api = get(ngRecordingsApiAtom);

    return {
      mutationKey: ["delete-recording", recordingId],
      mutationFn: async () => {
        await api.recording.deleteRecordingRecordingV1RecordingsRecordingIdDelete(
          recordingId,
        );

        // TODO: We should wait for the status to change before we release the promise. But this can only be done once we are certain the socket is enabled.
        //       And we should probably also limit that to a maximum amount of seconds that we will wait.
      },
      onSettled: () => {
        // TODO: Need to build some form of helper to flush the recordings state on a mutation ... .
        // TODO: When we modified the logic to inject a temporary status, we can remove the instant flush and just make it delayed.
        flushRecordingsState(get(queryClientAtom), get, true);
      },
    };
  });

  const hasToBeCancelled = status === "planned";

  return {
    actionAtom: atom(undefined, async (_, set) => {
      const { title, description, ok, cancel } = hasToBeCancelled
        ? {
            title: "dialog_cancel_recording_title",
            description: "dialog_cancel_recording_subtitle",
            ok: "button_yes",
            cancel: "button_no",
          }
        : {
            title: "dialog_remove_recording_title",
            description: "dialog_remove_recording_subtitle",
            ok: "button_recordings_delete",
            cancel: "dialog_button_cancel",
          };

      set(
        dialogAtom,
        actionDialogOpen({
          title: { key: title },
          description: { key: description },
          // TODO: Swap this out with something else more suited & shareable w/ tizen as well as web.
          lastFocusKey: "",
          type: "actions",
          id: "confirm-recordings-deletion",
          actions: [
            {
              label: { key: ok },
              key: "confirm",
              variant: "filled",
              action: () => get(mutationAtom).mutateAsync(),
            },
            {
              label: { key: cancel },
              action: () => {
                // TODO: Extend actions atom to allow action to be something other than just a function.
              },
              key: "cancel",
              initialFocus: true,
            },
          ],
        }),
      );
    }),
    isLoading: get(mutationAtom).isPending,
    action: status === "planned" ? "cancel" : "delete",
  };
};
