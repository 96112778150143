import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { isFirebaseEnabled } from "@sunrise/firebase";

import { playerSessionAtom } from "./atoms/analytics-player-session.atom";
import { emitAppLanguageChangeEffect } from "./effects/emit-app-language-change.effect";
import { emitGlobalsEffect } from "./effects/emit-globals.effect";
import { emitLoginLogoutAndUserIdEffect } from "./effects/emit-login-logout-and-user-id.effect";
import { emitPageEffect } from "./effects/emit-page.effect";

/**
 * A single run effect that is responsible for all analytics.
 *
 * This is the one that should be mounted in the app to run analytics.
 */
export const analyticsEffectAtom = isFirebaseEnabled
  ? atomEffect((get) => {
      get(emitPageEffect);
      get(emitAppLanguageChangeEffect);
      get(emitGlobalsEffect);
      get(playerSessionAtom);
      get(emitLoginLogoutAndUserIdEffect);
    })
  : atom(null);
