import { atom } from "jotai";
import { unwrap } from "jotai/utils";
import { atomEffect } from "jotai-effect";

import type { UserId } from "@sunrise/backend-types-core";
import { logEvent, setUserId, setUserProperties } from "@sunrise/firebase";
import { selectCurrentUserId } from "@sunrise/jwt";
import type { Nullable } from "@sunrise/utils";
import { userAtom } from "@sunrise/yallo-user";

const UNWRAPPED_USER_ATOM = unwrap(userAtom);

const LAST_KNOWN_USER_ID_ATOM = atom<Nullable<UserId>>(null);

/**
 * Effect that emits login and logout events when the user ID changes.
 *
 * This is used to track the user ID and the login method.
 *
 * This is emitted as the userId and properties related to the user.
 */
export const emitLoginLogoutAndUserIdEffect = atomEffect((get, set) => {
  const user = get(selectCurrentUserId);
  const lastKnownUserId = get(LAST_KNOWN_USER_ID_ATOM);

  if (!user && !lastKnownUserId) {
    return;
  }

  get(emitUserPropertiesEffect);

  if (user && user !== lastKnownUserId) {
    set(LAST_KNOWN_USER_ID_ATOM, user);
    logEvent("login", {
      // TODO: set method but we need to track that from the start.
      method: "unknown",
    });

    setUserId(user);
  }

  if (!user && lastKnownUserId) {
    set(LAST_KNOWN_USER_ID_ATOM, null);
    setUserId(null);

    logEvent("logout", {});
  }
});

const emitUserPropertiesEffect = atomEffect((get) => {
  const userData = get(UNWRAPPED_USER_ATOM);

  if (!userData) {
    return;
  }

  setUserProperties({
    plan: userData.data?.account?.plan?.display_name ?? "unknown",
    type: userData.data?.type ?? "unknown",
  });
});
