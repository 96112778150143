import type { ReactNode } from "react";

import type { Translatable } from "@sunrise/i18n";
import { useTranslatable } from "@sunrise/translator";
import { BBCodeText } from "@sunrise/yallo-web-components";

import styles from "./error-dialog-description.module.css";

type ErrorDialogDescriptionProps = {
  eventId: string | undefined;
  at?: Date;
  description: Translatable;
};

export function ErrorDialogDescription({
  at,
  eventId,
  description,
}: ErrorDialogDescriptionProps): ReactNode {
  const t = useTranslatable();
  if (!eventId) {
    return t(description);
  }

  return (
    <span className={styles.description}>
      <BBCodeText>{t(description)}</BBCodeText>
      <div className={styles.secondary}>
        <span>eventId: {eventId}</span>
        {at && <span>at: {at.toISOString()}</span>}
      </div>
    </span>
  );
}
