import { useCallback, useRef } from "react";
import { useSetAtom } from "jotai";

import type { Page } from "@sunrise/backend-types";

import { PageContext } from "./page-context";
import { pageIdAtom } from "./page-id.atom";

/**
 * It exposes a function on which pages can announce that they are shown.
 * As soon as the page is removed, a cleanup function is called.
 *
 * We can have a stack where pages can announce themselves under themselves.
 * So that means we can have the home page, and open the settings dialog on it.
 * When the home page opens, the home page is the active pageId.
 * When the settings dialog opens, the settings dialog is the active pageId.
 * When the settings dialog closes, the home page is the active pageId again.
 */
export const PageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const stackRef = useRef<Page[]>([]);
  const setPageId = useSetAtom(pageIdAtom);

  const showPage = useCallback(
    (page: Page) => {
      const length = stackRef.current.push(page);
      setPageId({ page, at: new Date() });

      return () => {
        stackRef.current.splice(length - 1, 1);
        const currentPage = stackRef.current[stackRef.current.length - 1];
        setPageId({ page: currentPage, at: new Date() });
      };
    },
    [setPageId],
  );

  return (
    <PageContext.Provider value={{ showPage }}>{children}</PageContext.Provider>
  );
};
