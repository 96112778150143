import { lazy, Suspense } from "react";
import { Switch } from "wouter";

import { PageSpinner } from "@sunrise/yallo-web-components";

import { rawRoute } from "@/config/route";

import { ErrorBoundaryRoute } from "./error-boundary-route";
import { useAuthentication } from "./hooks/use-authentication";
import { ProtectedRoute } from "./protected-route";
import { HomeSkeleton } from "./routes/home.skeleton";

const isProd = import.meta.env.MODE === "production";

// dynamic imports
const Tv = lazy(() => import("@/routes/tv"));
const Guide = lazy(() => import("@/routes/guide"));
const Home = lazy(() => import("@/routes/home"));
const Login = lazy(() => import("@/routes/login"));
const Pin = lazy(() => import("@/routes/pin"));
const Authenticate = lazy(() => import("@/routes/authenticate"));
const NotFound = lazy(() => import("@/routes/not-found"));
const Details = lazy(() => import("@/routes/details/details"));
const DevTools = lazy(() => import("@/routes/dev-tools/dev-tools"));
const DevToolsFeatureFlags = lazy(
  () => import("@/routes/dev-tools/feature-flags"),
);

const Recordings = lazy(() => import("@/routes/recordings/recordings"));
const RecordingDetailsEpisode = lazy(
  () => import("@/routes/recordings/recording-details-episode"),
);
const RecordingDetailsSeries = lazy(
  () => import("@/routes/recordings/recording-details-series"),
);
const RecordingDetailsSingle = lazy(
  () => import("@/routes/recordings/recording-details-single"),
);

export function Router(): JSX.Element {
  // We need to run the authentication effect here to make sure that the id_token is processed.
  // If we do not do this, the autostart will not work.
  // We should also not do this purely on the `/authenticate` route, because we need to set the id_token as processed on any page.
  useAuthentication();

  return (
    <Suspense fallback={<PageSpinner />}>
      <Switch>
        <ErrorBoundaryRoute
          component={() => (
            <Suspense fallback={<HomeSkeleton />}>
              <Home />
            </Suspense>
          )}
          path={rawRoute.home}
        />
        <ErrorBoundaryRoute component={Guide} path={rawRoute.guide} />
        <ErrorBoundaryRoute component={Recordings} path={rawRoute.recordings} />
        <ErrorBoundaryRoute
          component={RecordingDetailsEpisode}
          path={rawRoute.recordingEpisodeDetails}
        />
        <ErrorBoundaryRoute
          component={RecordingDetailsSeries}
          path={rawRoute.recordingSeriesDetails}
        />
        <ErrorBoundaryRoute
          component={RecordingDetailsSingle}
          path={rawRoute.recordingSingleDetails}
        />
        <ErrorBoundaryRoute component={Tv} path={rawRoute.tv} />
        <ErrorBoundaryRoute component={Details} path={rawRoute.details} />
        <ErrorBoundaryRoute component={Login} path={rawRoute.login} />
        {!isProd && (
          <>
            <ErrorBoundaryRoute component={DevTools} path={rawRoute.devTools} />
            <ErrorBoundaryRoute
              component={DevToolsFeatureFlags}
              path={rawRoute.devToolsFeatureFlags}
            />
          </>
        )}
        <ErrorBoundaryRoute
          component={Authenticate}
          path={rawRoute.authenticate}
        />
        <ProtectedRoute component={Pin} path={rawRoute.pin} />
        <ErrorBoundaryRoute component={NotFound} />
      </Switch>
    </Suspense>
  );
}
