import type { PlayerState } from "@sunrise/player";

export function convertToPlayerState(
  state: PlayerState,
): "play" | "pause" | "error" {
  switch (state) {
    case "playing":
      return "play";
    case "error":
      return "error";
    case "paused":
    case "stopped":
    case "loading":
    case "idle":
    case "loaded":
    case "suspended":
      return "pause";
  }
}
