import { useAtom, useAtomValue } from "jotai/react";

import { AsPage } from "@sunrise/pages";
import { searchIsOpenAtom, selectSearchEnabled } from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";
import { Button, Dialog } from "@sunrise/yallo-web-components";

import styles from "./search-dialog.module.css";
import { SearchFiltersGroup } from "./search-filters-group";
import { SearchHistory } from "./search-history";
import { SearchHistoryHeader } from "./search-history-header";
import { SearchInput } from "./search-input";
import { SearchResults } from "./search-results";

export const SearchDialog = () => {
  const t = useTranslator();
  const isSearching = useAtomValue(selectSearchEnabled);
  const [open, setOpen] = useAtom(searchIsOpenAtom);
  const onClose = () => setOpen(false);

  return (
    <Dialog
      className={styles.searchDialog}
      open={open}
      closable
      onClose={onClose}
    >
      <AsPage page="search_page">
        <div className={styles.container}>
          <header className={styles.header}>
            <div className={styles.inputWrapper}>
              <Button
                icon="arrowLeft"
                variant="text"
                hideLabel
                onClick={onClose}
              >
                {t("button_go_back")}
              </Button>
              <SearchInput />
            </div>
            {isSearching ? <SearchFiltersGroup /> : <SearchHistoryHeader />}
          </header>
          {isSearching ? <SearchResults /> : <SearchHistory />}
        </div>
      </AsPage>
    </Dialog>
  );
};
