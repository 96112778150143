import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { logEvent, setDefaultProperties } from "@sunrise/firebase";
import { currentLanguageAtom } from "@sunrise/i18n";

const lastLoggedLanguage = atom<string | null>(null);

/**
 * Effect that emits analytics events when the app language changes.
 *
 * This is used to set the language in the analytics events.
 * This is emitted as a default property. So it is known for all future events.
 */
export const emitAppLanguageChangeEffect = atomEffect((get, set) => {
  const currentLanguage = get(currentLanguageAtom);

  const last = get(lastLoggedLanguage);
  if (last === currentLanguage) {
    return;
  }

  set(lastLoggedLanguage, currentLanguage);

  logEvent("client_language", {
    client_language: currentLanguage,
  });

  setDefaultProperties({
    client_language: currentLanguage,
  });
});
