import type { ComponentProps, ReactElement } from "react";
import clsx from "clsx";

import { Switch as BaseSwitch } from "../../form/toggle/switch";
import styles from "./switch.module.css";

type Props = ComponentProps<typeof BaseSwitch>;

const Switch = ({ className, ...props }: Props): ReactElement => {
  return <BaseSwitch className={clsx(styles.switch, className)} {...props} />;
};

export { Switch };
