import { useRef } from "react";
import * as Sentry from "@sentry/react";
import logoUrl from "static/icons/logo.svg";

import { getErrorMessage } from "@sunrise/yallo-error-mapping";
import { Button, Text, Title } from "@sunrise/yallo-web-components";

import styles from "./error-boundary.module.css";

export type MonitoringErrorBoundaryProps = {
  children: JSX.Element;
  fallback?: Sentry.FallbackRender;
  fullPage?: boolean;
};

const fallback: (
  props: Parameters<Sentry.FallbackRender>[0] & {
    fullPage?: boolean;
    date: string;
  },
) => React.ReactElement = ({ error, eventId, resetError, fullPage, date }) => {
  return (
    <div className={styles.fallback}>
      {fullPage && (
        <img
          alt="Yallo"
          className={styles.logo}
          height={79}
          src={logoUrl}
          width={179}
        />
      )}

      <Title
        className={styles.title}
        level="h1"
        size="large"
        variant="headline"
      >
        Oh snap!
      </Title>
      <Text as="p" className={styles.errorMessage} size="large" variant="body">
        {getErrorMessage(error)}
      </Text>

      <div className={styles.eventInformation}>
        <Text as="p">eventId: {eventId}</Text>
        <Text as="p">at: {date}</Text>
        <Button
          className={styles.reload}
          variant="tonal"
          onClick={() => {
            // We hide the logo if the error takes place in individual pages (the header is still shown at that point)
            // The errors we experience on a page might be recoverable so we allow the user to reset the error.
            if (fullPage) return window.location.reload();
            // If the error happens at a higher level it's likely that everything is broken and we'll reload the page instead.
            resetError();
          }}
        >
          Try again
        </Button>
      </div>
    </div>
  );
};

export function ErrorBoundary(
  props: MonitoringErrorBoundaryProps,
): JSX.Element {
  const date = useRef(new Date().toISOString());

  return (
    <Sentry.ErrorBoundary
      fallback={(fallbackProps) => {
        const FallbackComponent = props.fallback ?? fallback;

        return (
          <FallbackComponent
            {...fallbackProps}
            date={date.current}
            fullPage={props.fullPage}
          />
        );
      }}
      onError={
        import.meta.env.MODE !== "production"
          ? (e) => console.error(e)
          : undefined
      }
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
