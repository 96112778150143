import { atomEffect } from "jotai-effect";

import { logEvent } from "@sunrise/firebase";
import { pageIdAtom } from "@sunrise/pages";

/**
 * Emits a page_view event when the page ID changes.
 *
 * When there is no page known / mapped yet we set it to "unknown".
 * This should allow us to identify places where we don't have a page ID yet.
 */
export const emitPageEffect = atomEffect((get) => {
  const pageId = get(pageIdAtom);

  logEvent("page_view", {
    page_id: pageId?.page ?? "unknown",
  });
});
