import type { ReactNode } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import type { Dialogs } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";
import { BBCodeText, Text } from "@sunrise/yallo-web-components";

import styles from "./dialog-container.module.css";

export const DialogContainer = ({
  dialog,
  children,
  "data-testid": testId,
}: {
  dialog: Dialogs;
  children: ReactNode;
  ["data-testid"]: string;
}): ReactNode => {
  const translate = useTranslatable();

  const translatedTitle = translate(dialog.title);

  const description =
    dialog.type !== "list" && dialog.description ? (
      typeof dialog.description === "object" && "type" in dialog.description ? (
        dialog.description.component
      ) : (
        <BBCodeText>{translate(dialog.description)}</BBCodeText>
      )
    ) : null;

  return (
    <>
      <Dialog.Title className={translatedTitle ? styles.title : undefined}>
        <Text size="large" variant="title">
          {translatedTitle}
        </Text>
      </Dialog.Title>

      {description ? (
        <Dialog.Description data-testid={`${testId}.description`}>
          <Text size="medium" variant="body">
            {description}
          </Text>
        </Dialog.Description>
      ) : null}
      {children}
    </>
  );
};
