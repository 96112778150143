import { clsx } from "clsx";

import { type RecommendationData } from "@sunrise/yallo-recommendations";
import { Title } from "@sunrise/yallo-web-components";

import { ScrollableRowSkeleton } from "@/components/home-row/scrollable-row.skeleton";

import styles from "./home.module.css";

// The height of a recommendation row with the default "box" variant, its title and the spacing in between
// By dividing the window height by this value, we can determine how many rows to render when we don't have any data yet
const DEFAULT_ROW_HEIGHT = 352;

export function HomeSkeleton({
  recommendations,
}: {
  recommendations?: RecommendationData[];
}) {
  const skeletons = recommendations ?? [
    ...new Array(
      Math.max(1, Math.ceil(window.innerHeight / DEFAULT_ROW_HEIGHT)),
    ),
  ];

  return (
    <main className={styles.container} data-testid="home">
      <div className={styles.skeleton} data-testid="home-skeleton">
        {skeletons.map((recommendation, index) => (
          <div key={index}>
            <div>
              {recommendation?.title ? (
                <>
                  <Title
                    className={styles.knownTitle}
                    level="h2"
                    size="small"
                    variant="headline"
                  >
                    {recommendation?.title}
                  </Title>
                </>
              ) : (
                <div className={clsx("skeleton-item", styles.title)} />
              )}
            </div>
            <ScrollableRowSkeleton
              variant={recommendation?.displayConfiguration.displayType}
            />
          </div>
        ))}
      </div>
    </main>
  );
}
