import clsx from "clsx";
import { useAtomValue } from "jotai";

import { showLiveTagAtom } from "@sunrise/player";
import { usePlayChannel } from "@sunrise/yallo-common-player-manager";
import { Button, LiveTag } from "@sunrise/yallo-web-components";

import styles from "./tv-live-tag.module.css";

function TvLiveTag({ className }: Pick<CommonProps, "className">) {
  const { isAtLivePosition, shouldShowTag, currentChannelId } =
    useAtomValue(showLiveTagAtom);

  const live = usePlayChannel({ channelId: currentChannelId });

  if (!shouldShowTag) return null;

  const handleClick = () => {
    if (!isAtLivePosition) {
      live.play();
    }
    return;
  };

  return (
    <Button
      aria-pressed={isAtLivePosition}
      className={clsx(styles.button, className)}
      variant="none"
      onClick={handleClick}
    >
      <LiveTag
        active={isAtLivePosition}
        className={clsx([!isAtLivePosition && styles.tagHover])}
      />
    </Button>
  );
}

export { TvLiveTag };
