import type { ReactElement } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import type { TrackId } from "@sunrise/player";
import {
  actionPlayerSelectAudio,
  actionPlayerSelectSubtitles,
  playerAtom,
  selectPlayerCurrentAudioTrackId,
  selectPlayerCurrentSubtitleTrackId,
  selectPlayerSubtitlesAndAudioSettings,
} from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { SlideOut } from "@sunrise/yallo-web-components";

export const PlayerAudioAndSubtitles = (): ReactElement => {
  const { audioTracks, subtitleTracks } = useAtomValue(
    selectPlayerSubtitlesAndAudioSettings,
  );
  const activeAudioTrackId = useAtomValue(selectPlayerCurrentAudioTrackId);
  const activeSubtitleTrackId = useAtomValue(
    selectPlayerCurrentSubtitleTrackId,
  );
  const t = useTranslator();
  const dispatchPlayer = useSetAtom(playerAtom);

  const selectTrack =
    (section: "audio" | "subtitle") =>
    (value: TrackId | null): void => {
      const action =
        section === "audio"
          ? actionPlayerSelectAudio
          : actionPlayerSelectSubtitles;
      dispatchPlayer(action(value));
    };

  return (
    <div>
      <SlideOut.Title level="h3" size="small">
        {t("player_audio")}:
      </SlideOut.Title>
      {audioTracks.map(({ label, format, value }) => (
        <SlideOut.Radio
          key={value}
          checked={value === activeAudioTrackId}
          labelPosition="end"
          name="audio"
          onChange={() => selectTrack("audio")(value)}
        >
          {t(label)}
          {format ? ` ${format}` : ""}
        </SlideOut.Radio>
      ))}
      <SlideOut.Title level="h3" size="small">
        {t("player_subtitles")}:
      </SlideOut.Title>
      {subtitleTracks.map(({ label, value }) => (
        <SlideOut.Radio
          key={value}
          checked={value === activeSubtitleTrackId}
          labelPosition="end"
          name="subtitles"
          onChange={() => selectTrack("subtitle")(value)}
        >
          {t(label)}
        </SlideOut.Radio>
      ))}
    </div>
  );
};
