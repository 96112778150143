import areEqual from "fast-deep-equal";
import { selectAtom } from "jotai/utils";
import type { Getter, Setter } from "jotai/vanilla";
import { atom } from "jotai/vanilla";
import { atomEffect } from "jotai-effect";
import { v4 as uuid } from "uuid";

import { PlayerContentType } from "@sunrise/backend-ng-events";
import type { Schedulable } from "@sunrise/backend-types";
import type {
  ChannelId,
  EPGEntryId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { logEvent, setDefaultProperties } from "@sunrise/firebase";
import {
  playerCurrentContentAtom,
  playerCurrentDateTimeAtom,
  selectPlayerCurrentAudioTrack,
  selectPlayerCurrentPlayRequest,
  selectPlayerCurrentSubtitleTrack,
  selectPlayerState,
} from "@sunrise/player";
import { getLiveProgress } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";
import { isDefined, stableLoadable } from "@sunrise/utils";

import type { AnalyticsPlayerSessionId } from "../types";
import { convertToPlayerState } from "../utils/convert-to-player-state";

function generateAnalyticsPlayerSessionId(): AnalyticsPlayerSessionId {
  return uuid() as AnalyticsPlayerSessionId;
}

const _playerSessionAtom = atom<{
  id: AnalyticsPlayerSessionId;
  content: CurrentContent;
} | null>(null);

/**
 * When the sessions are not equal we can not report changes in the player to the playout session.
 * Instead we are waiting to send the video_complete event with the syncPlayerState and the stored values.
 */
const validSessionToReportOnAtom = atom((get) => {
  const session = get(_playerSessionAtom);
  const content = get(playerCurrentContentForAnalyticsAtom);

  return (
    session &&
    content &&
    areEqual(
      getPrimaryAttributes(session.content),
      getPrimaryAttributes(content),
    ) &&
    session
  );
});

/**
 * The player session is a unique ID which represents a specific program being played out.
 * With every unique ID, a program will be associated. A program will be something like linear playout and identified by the EpgID / RecordingId etc.
 *
 * video_start (on start)
 * video_complete (on end of playout. It will emit the last known/stored values for all the "changing" attributes.)
 * video_state (on state change)
 * video_progress (on progress change)
 * video_audio_language (on audio language change)
 * video_subtitle_language (on subtitle language change)
 *
 * The following attributes are always sent (when available):
 * - video_content_id
 * - video_channel_id
 * - video_channel_name
 * - video_epg_id
 * - video_type (replay, live, recording)
 * - video_recording_id
 * - video_duration
 * - video_title
 *
 * The following attributes are emitted when available or when they change:
 * - video_progress (Emits when not available earlier and then whenever the progress changes "enough" to emit. See bucketProgress for more details.)
 * - video_state (Emits when not available earlier and then whenever the state changes. Eveything is mapped to play/pause)
 * - video_audio_language (Emits when not available earlier and then whenever the audio language changes)
 * - video_subtitle_language (Emits when not available earlier and then whenever the subtitle language changes)
 */
export const playerSessionAtom = atom((get) => {
  get(createPlayerSessionEffect);
  get(emitPlayerProgressEffect);
  get(emitPlayerStateChangeEffect);
  get(emitPlayerAudioLanguageEffect);
  get(emitPlayerSubtitleLanguageEffect);

  return get(_playerSessionAtom);
});

type CurrentContent = {
  type: PlayerContentType;
  epgId: Nullable<EPGEntryId>;
  channelId: Nullable<ChannelId>;
  recordingId: RecordingId | null;
  /**
   * Expressed in seconds.
   */
  duration: number | null;
  channelName: Nullable<string>;
  contentName: Nullable<string>;
  schedule: Nullable<Schedulable>;
};

const UNWRAP_FOR_CURRENT_EPG_ITEM_ATOM = stableLoadable(
  playerCurrentContentAtom,
);

/**
 * Returns the necessary program details for analytics in all cases.
 * Depending on the playrequest.
 */
const playerCurrentContentForAnalyticsAtom = atom<CurrentContent | null>(
  (get) => {
    const program = get(UNWRAP_FOR_CURRENT_EPG_ITEM_ATOM);
    const playRequest = get(selectPlayerCurrentPlayRequest);

    // The problem is that the program stays the same while the underlying playRequest already changed.
    // We need to make sure that when the playRequest for the program differs, we should assume the details are wrong.
    // This also only happens because we are using a stableLoadable.
    // And we should be using stableLoadable because we do not want to suddenly have no content when some data is reloading.
    if (
      !program ||
      !playRequest ||
      !areEqual(playRequest, program.playRequest)
    ) {
      return null;
    }

    return {
      type: playRequest.type,
      epgId: program.epgId,
      channelId: program.channelId,
      recordingId:
        playRequest.type === PlayerContentType.Recording
          ? playRequest.recordingId
          : null,
      duration: program.schedule
        ? (program.schedule.endTime.getTime() -
            program.schedule.startTime.getTime()) /
          1000
        : null,
      channelName: program.channelName,
      contentName: program.title,
      schedule: program.schedule,
    };
  },
);

/**
 * Should return the progress of the current content.
 * Should work for linear content or content mapped to linear content (so live, replay & recordings).
 */
const playerCurrentContentProgressAtom = atom((get) => {
  const session = get(validSessionToReportOnAtom);
  if (!session || !session.content.schedule) {
    return null;
  }

  const currentTimePlayer = get(playerCurrentDateTimeAtom);
  if (!currentTimePlayer) {
    return null;
  }

  return getLiveProgress(
    session.content.schedule.startTime,
    session.content.schedule.endTime,
    currentTimePlayer,
    true,
  );
});

const selectPlayerStateForAnalyticsAtom = selectAtom(
  selectPlayerState,
  (state) => convertToPlayerState(state),
);
const lastEmittedStateAtom = atom<Nullable<"play" | "pause" | "error">>(null);
const emitPlayerStateChangeEffect = atomEffect((get, set) => {
  const session = get(validSessionToReportOnAtom);
  if (!session) {
    set(lastEmittedStateAtom, null);
    return;
  }

  const lastEmittedState = get(lastEmittedStateAtom);
  const state = get(selectPlayerStateForAnalyticsAtom);
  if (state === lastEmittedState) {
    return;
  }

  logEvent("video_state", syncPlayerState(get, set, session));
});

const lastEmittedProgressAtom = atom<number | null>(null);

/**
 * When the video progresses past 10%, 25%, 50%, and 75% duration time, we need to emit an event.
 * For recordings, the pre & post padding is not included in the duration.
 * So it will always be 0 before the pre-padding is completed and always 100 inside the post-padding.
 * Ideally it's -x % and 100+% but it's not implemented like that atm.
 */
const emitPlayerProgressEffect = atomEffect((get, set) => {
  const session = get(validSessionToReportOnAtom);
  if (!session) {
    return;
  }

  const lastEmittedProgress = get(lastEmittedProgressAtom);
  const progress = get(playerCurrentContentProgressAtom);
  if (!progress) {
    set(lastEmittedProgressAtom, null);
    return;
  }

  const bucket = bucketProgress(progress);
  if (
    isDefined(lastEmittedProgress) &&
    bucket === bucketProgress(lastEmittedProgress)
  ) {
    // We need to update the progress all the time so we can emit the correct progress on 'stored' sync. Aka, the final sync.
    set(lastEmittedProgressAtom, progress);
    return;
  }

  logEvent("video_progress", syncPlayerState(get, set, session));
});

const audioLanguageForAnalyticsSelector = selectAtom(
  selectPlayerCurrentAudioTrack,
  (track) => track?.lang ?? "none",
);
const lastEmittedAudioLanguageAtom = atom<Nullable<string>>(null);
const emitPlayerAudioLanguageEffect = atomEffect((get, set) => {
  const session = get(validSessionToReportOnAtom);
  if (!session) {
    return;
  }

  const lastEmittedAudioLanguage = get(lastEmittedAudioLanguageAtom);
  const lang = get(audioLanguageForAnalyticsSelector);

  if (lang === lastEmittedAudioLanguage) {
    return;
  }

  logEvent("video_audio_language", syncPlayerState(get, set, session));
});

/**
 * Peeks all the needed data and then ensures that the last known states are updated.
 * Only call this function when we are about to emit the response of this function.
 *
 * When we pass stored: true we no longer want to read the latest state but we want to make sure to use the last known previous value.
 */
function syncPlayerState(
  get: Getter & { peek: Getter },
  set: Setter,
  session: {
    id: AnalyticsPlayerSessionId;
    content: CurrentContent;
  },
  options?: { stored: boolean },
) {
  const stored = options?.stored ?? false;
  const subtitleLang = get.peek(
    stored
      ? lastEmittedSubtitleLanguageAtom
      : subtitleLanguageForAnalyticsSelector,
  );
  const audioLang = get.peek(
    stored ? lastEmittedAudioLanguageAtom : audioLanguageForAnalyticsSelector,
  );
  const progress = get.peek(
    stored ? lastEmittedProgressAtom : playerCurrentContentProgressAtom,
  );
  const state = get.peek(
    stored ? lastEmittedStateAtom : selectPlayerStateForAnalyticsAtom,
  );

  if (!stored) {
    set(lastEmittedAudioLanguageAtom, audioLang);
    set(lastEmittedSubtitleLanguageAtom, subtitleLang);
    // TODO: We somehow keep emitting the progress
    set(lastEmittedProgressAtom, progress ?? null);
    set(lastEmittedStateAtom, state);
  }

  return {
    video_content_id: session.id,
    video_channel_id: session.content.channelId,
    video_epg_id: session.content.epgId,
    video_type: session.content.type,
    video_recording_id: session.content.recordingId,
    video_duration: session.content.duration,
    video_title: session.content.contentName,
    video_channel_name: session.content.channelName,
    video_progress: progress,
    video_state: state,
    video_audio_language: audioLang,
    video_subtitle_language: subtitleLang,
  };
}

const subtitleLanguageForAnalyticsSelector = selectAtom(
  selectPlayerCurrentSubtitleTrack,
  (track) => track?.lang ?? "none",
);
const lastEmittedSubtitleLanguageAtom = atom<Nullable<string>>(null);
const emitPlayerSubtitleLanguageEffect = atomEffect((get, set) => {
  const session = get(validSessionToReportOnAtom);
  if (!session) {
    set(lastEmittedSubtitleLanguageAtom, null);
    return;
  }

  const lastEmittedSubtitleLanguage = get(lastEmittedSubtitleLanguageAtom);
  const lang = get(subtitleLanguageForAnalyticsSelector);

  if (lang === lastEmittedSubtitleLanguage) {
    return;
  }

  logEvent("video_subtitle_language", syncPlayerState(get, set, session));
});

/**
 * Emits all the necessary effects.
 */
const createPlayerSessionEffect = atomEffect((get, set) => {
  const session = get(_playerSessionAtom);
  const content = get(playerCurrentContentForAnalyticsAtom);

  if (
    session &&
    content &&
    areEqual(
      getPrimaryAttributes(session.content),
      getPrimaryAttributes(content),
    )
  ) {
    return;
  }

  // When we have a previous session we need to complete that first.
  if (session) {
    logEvent(
      "video_complete",
      syncPlayerState(get, set, session, { stored: true }),
    );
  }

  // When we don't have new content, we need to reset that.
  if (!content) {
    setDefaultProperties({
      video_content_id: null,
    });
    set(_playerSessionAtom, null);
    return;
  }

  // When we don't have a session or it mismatches, we need to create a new one.
  const id = generateAnalyticsPlayerSessionId();

  setDefaultProperties({
    video_content_id: id,
  });

  logEvent("video_start", syncPlayerState(get, set, { id, content }));

  set(_playerSessionAtom, {
    id,
    content,
  });
});

function getPrimaryAttributes(
  content: CurrentContent,
): Pick<CurrentContent, "epgId" | "type" | "recordingId" | "channelId"> {
  return {
    epgId: content.epgId,
    type: content.type,
    recordingId: content.recordingId,
    channelId: content.channelId,
  };
}

function bucketProgress(progress: number): number {
  if (progress >= 10 && progress <= 25) {
    return 10;
  } else if (progress > 25 && progress <= 50) {
    return 25;
  } else if (progress > 50 && progress <= 75) {
    return 50;
  } else if (progress > 75 && progress <= 100) {
    return 75;
  }

  return 0;
}
