import type { ComponentProps } from "react";

import type { ActionsDialog as ActionsDialogType } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";
import { Button, Link } from "@sunrise/yallo-web-components";

import type { DialogProps } from "@/components/dialogs/dialog.types";

import styles from "./actions-dialog.module.css";

export const ActionsDialog = ({
  dialog,
  "data-testid": testId,
  doClose,
}: DialogProps & { dialog: ActionsDialogType }) => {
  const t = useTranslatable();

  return (
    <div className={styles.actions} data-testid={`${testId}.actions`}>
      {"actions" in dialog &&
        dialog.actions.map((action, i) => {
          const key = action.key ?? "action-" + i;
          const label = t(action.label) ?? key ?? `button ${i}`;
          type ButtonProps = ComponentProps<typeof Button>;

          const sharedProps: Pick<
            ButtonProps,
            "className" | "data-testid" | "variant"
          > = {
            className: styles.button,
            "data-testid": `${testId}.button.${key}`,
            variant: action.variant ?? ("outlined" as const),
          };

          if ("url" in action) {
            return (
              <Link key={key} {...sharedProps} href={action.url}>
                {label}
              </Link>
            );
          }

          return (
            <Button
              key={key}
              {...sharedProps}
              onClick={() => {
                void action.action?.();
                if (!action.closeDisabled) {
                  doClose?.();
                }
              }}
            >
              {label}
            </Button>
          );
        })}
    </div>
  );
};
