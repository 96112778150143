import type { Page } from "@sunrise/backend-types";

import { useSetActivePage } from "./use-set-active-page";

/**
 * Wrap a component to identify itself as a page when mounted.
 *
 * It is possible that a key attribute is needed to ensure multiple page hits register in a row when they are of the same Page name.
 * For example going from one detail page to another detail page of the same type should be counted as 2 page hits.
 */
export const AsPage = ({
  page,
  children,
}: React.PropsWithChildren & { page: Page }): React.ReactNode => {
  useSetActivePage(page);

  return children;
};
