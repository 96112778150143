import { type ReactElement, useCallback, useState } from "react";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";

import { type Icons } from "@sunrise/icons";
import {
  actionPlayerToggleFullscreen,
  playerAtom,
  selectPlayerIsPlaying,
  selectPlayerShouldBeFullscreen,
} from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import {
  canSeekInDirectionAtom,
  usePlayFromBeginning,
  usePlayNext,
  useQuickSeek,
  useTogglePlayPause,
} from "@sunrise/yallo-player-controls";
import { Button } from "@sunrise/yallo-web-components";

import { PlayerMoreOptions } from "../more-options/player-more-options";
import styles from "./player-controls.module.css";
import { PlayerSeekbar } from "./player-seekbar";
import { PlayerThumbnail } from "./player-thumbnail";
import { TvLiveTag } from "./tv-live-tag";
import { VolumeControl } from "./volume-control";

type PlayerControlsProps = CommonProps;

const FORWARD_SECONDS = 30;
const BACKWARD_SECONDS = 15;

function PlayerControls({ className }: PlayerControlsProps): ReactElement {
  const t = useTranslator();
  const dispatchPlayer = useSetAtom(playerAtom);
  const isFullscreen = useAtomValue(selectPlayerShouldBeFullscreen);
  const { toggle: togglePlayPause } = useTogglePlayPause();
  const isPlaying = useAtomValue(selectPlayerIsPlaying);
  const playFromBeginning = usePlayFromBeginning();
  const { visible: canPlayNext, playNext } = usePlayNext();
  const { forward, backward } = useQuickSeek({
    forwardSeconds: FORWARD_SECONDS,
    backwardSeconds: BACKWARD_SECONDS,
  });
  const canSeek = useAtomValue(canSeekInDirectionAtom);
  const toggleFullscreen = useCallback(() => {
    dispatchPlayer(actionPlayerToggleFullscreen());
  }, [dispatchPlayer]);
  const [showDurationLeft, setShowDurationLeft] = useState(false);

  const controls: {
    icon: Icons;
    label: string;
    onClick: () => void;
    disabled?: boolean;
  }[] = [
    {
      icon: "previous",
      label: t("previous"),
      onClick: playFromBeginning,
    },
    {
      icon: "previous15",
      label: t("rewind_n_seconds", [BACKWARD_SECONDS]),
      onClick: backward,
      disabled: !canSeek.backwards,
    },
    {
      icon: isPlaying ? "pause" : "play",
      label: t("button_play_pause"),
      onClick: togglePlayPause,
    },
    {
      icon: "next30",
      label: t("forward_n_seconds", [FORWARD_SECONDS]),
      onClick: forward,
      disabled: !canSeek.forwards,
    },
    {
      icon: "next",
      label: t("next"),
      onClick: playNext,
      disabled: !canPlayNext,
    },
  ];

  return (
    <div className={clsx([styles.controls, className])}>
      <div className={styles.buttonRegion}>
        <TvLiveTag className={styles.liveTag} />
        <div className={styles.buttons}>
          {controls.map(({ icon, label, onClick, disabled }) => (
            <Button
              key={label}
              disabled={disabled}
              icon={icon}
              variant="text"
              hideLabel
              onClick={onClick}
            >
              {label}
            </Button>
          ))}
        </div>
      </div>
      <div className={styles.seekWrapper}>
        <PlayerThumbnail showDurationLeft={showDurationLeft} />
        <PlayerSeekbar
          setShowDurationLeft={setShowDurationLeft}
          showDurationLeft={showDurationLeft}
        />
        <div className={styles.actions}>
          <VolumeControl />
          <Button
            aria-pressed={isFullscreen}
            icon={isFullscreen ? "fullscreenExit" : "fullscreen"}
            variant="text"
            hideLabel
            onClick={toggleFullscreen}
          >
            {t("toggle_fullscreen_button")}
          </Button>
          <PlayerMoreOptions />
        </div>
      </div>
    </div>
  );
}

export { PlayerControls };
