import { type ReactNode, useMemo } from "react";
import clsx from "clsx";

import styles from "./bb-code-text.module.css";

interface BBCodeTextProps {
  children: string | null;
  className?: string;
}

const parseAttributes = (tag: string): Record<string, string> => {
  const attributes: Record<string, string> = {};
  const regex = /(\w+)=([^\s\]]+)/g;
  let match;

  while ((match = regex.exec(tag)) !== null) {
    attributes[match[1]] = match[2];
  }

  return attributes;
};

const parseBBCode = (text: string): ReactNode[] => {
  const parts: ReactNode[] = [];
  let currentIndex = 0;
  let currentText = "";

  const addCurrentText = () => {
    if (currentText) {
      parts.push(currentText);
      currentText = "";
    }
  };

  while (currentIndex < text.length) {
    if (text[currentIndex] === "[") {
      const closeTagIndex = text.indexOf("]", currentIndex);
      if (closeTagIndex !== -1) {
        const fullTag = text.slice(currentIndex + 1, closeTagIndex);
        const tagMatch = fullTag.match(/^(\w+)(?:\s+.*)?$/);

        if (!tagMatch) {
          currentText += text[currentIndex];
          currentIndex++;
          continue;
        }

        const tagName = tagMatch[1].toLowerCase();
        const endTag = `[/${tagName}]`;
        const endTagIndex = text.indexOf(endTag, closeTagIndex + 1);

        if (endTagIndex !== -1) {
          addCurrentText();
          const content = text.slice(closeTagIndex + 1, endTagIndex);

          switch (tagName) {
            case "b":
              parts.push(
                <strong key={parts.length}>{parseBBCode(content)}</strong>,
              );
              break;
            case "i":
              parts.push(<em key={parts.length}>{parseBBCode(content)}</em>);
              break;
            case "url":
              parts.push(
                <a
                  key={parts.length}
                  href={content}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {content}
                </a>,
              );
              break;
            case "a": {
              const attrs = parseAttributes(fullTag);
              parts.push(
                <a
                  key={parts.length}
                  href={attrs.href}
                  rel={
                    attrs.target === "_blank"
                      ? "noopener noreferrer"
                      : undefined
                  }
                  target={attrs.target}
                >
                  {parseBBCode(content)}
                </a>,
              );
              break;
            }
            default:
              currentText += text.slice(currentIndex, closeTagIndex + 1);
              currentIndex = closeTagIndex;
              continue;
          }

          currentIndex = endTagIndex + endTag.length;
          continue;
        }
      }
    }

    currentText += text[currentIndex];
    currentIndex++;
  }

  addCurrentText();
  return parts;
};

export const BBCodeText = ({
  children,
  className,
}: BBCodeTextProps): ReactNode => {
  const parsed = useMemo(
    () => (children ? parseBBCode(children) : null),
    [children],
  );

  if (!parsed) {
    return null;
  }

  return <span className={clsx([className, styles.root])}>{parsed}</span>;
};
