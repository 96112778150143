import { BaseError, type BaseErrorOptions } from "@sunrise/error";

export class BackendRecordingsError extends BaseError {
  constructor(
    message: string,
    private readonly _options?: BaseErrorOptions,
  ) {
    super("RecordingsError", message, _options);
  }

  /**
   * Error will be handled by logging out the user.
   */
  override get canSwallow(): boolean {
    return true;
  }

  /**
   * We can assume it's correct what the backend tells us and we do not need to retry.
   */
  override get shouldRetry(): boolean {
    return false;
  }
}
