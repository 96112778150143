import { type ReactElement } from "react";
import { useMediaQuery } from "usehooks-ts";

import { usePauseAds } from "@sunrise/ads";
import { useTranslator } from "@sunrise/translator";
import { Button } from "@sunrise/yallo-web-components";

import styles from "./pause-ads.module.css";

export function PauseAds(): ReactElement | null {
  const t = useTranslator();
  const { url, width, height, onShown, onError, dismiss, shouldShowClose } =
    usePauseAds();
  /* The mobile player controls overlap with the image
   * - The image should never be obscured
   * - The user should always be able to continue playback
   * That's why we opted to always show the close button on smaller sizes
   */
  const isSmallScreen = useMediaQuery("(width <= 800px)");

  if (!url) return null;

  return (
    <div className={styles.container} data-testid="pause_ad">
      {(isSmallScreen || shouldShowClose) && (
        <Button
          className={styles.close}
          icon="close"
          variant="text"
          hideLabel
          onClick={dismiss}
        >
          {t("button_close")}
        </Button>
      )}
      <div>
        <img
          className={styles.image}
          data-testid="pause_ad.image"
          height={height ?? undefined}
          src={url}
          width={width ?? undefined}
          onError={onError}
          onLoad={onShown}
        />
      </div>
    </div>
  );
}
