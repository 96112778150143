import { clsx } from "clsx";
import { useAtom } from "jotai";

import { SearchFilters } from "@sunrise/backend-ng-search";
import { searchFilterAtom } from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";
import { Filter } from "@sunrise/yallo-web-components";

import styles from "./search-dialog.module.css";

export const SearchFiltersGroup = () => {
  const t = useTranslator();
  const [filter, setFilter] = useAtom(searchFilterAtom);
  const onChange = (value: SearchFilters | "") => {
    if (value === "") return;
    setFilter(value);
  };

  return (
    <Filter.Group
      className={clsx("u-horizontal-scroll", styles.searchFilters)}
      type="single"
      value={filter}
      onValueChange={onChange}
    >
      <Filter.Chip value={SearchFilters.All}>
        {t("search_page_filter_all_results")}
      </Filter.Chip>
      <Filter.Chip value={SearchFilters.Available}>
        {t("search_page_filter_available")}
      </Filter.Chip>
      <Filter.Chip value={SearchFilters.Upcoming}>
        {t("search_page_filter_upcoming")}
      </Filter.Chip>
      <Filter.Chip value={SearchFilters.Recorded}>
        {t("search_page_filter_recorded")}
      </Filter.Chip>
      <Filter.Chip value={SearchFilters.Channels}>
        {t("search_page_filter_channels")}
      </Filter.Chip>
    </Filter.Group>
  );
};
