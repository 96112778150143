import { getAnalytics, setUserProperties } from "firebase/analytics";

import { isNil } from "@sunrise/utils";

import { configureAnalytics } from "./configure-analytics";

export type FirebasePropertyType = "default" | "user";

const PROPERTY_MAP: Record<FirebasePropertyType, Map<string, unknown>> = {
  default: new Map(),
  user: new Map(),
};

/**
 * This function is responsible to update analytics directly to set the right properties in the right place.
 *
 * @param properties
 * @param scope
 */
export function updateDefaultProperties(
  properties: Record<string, unknown>,
  scope: FirebasePropertyType,
) {
  updateMap(PROPERTY_MAP[scope], properties);

  if (scope === "user") {
    // We need to call firebase's native function to set the user properties.
    setUserProperties(getAnalytics(), getDefaultProperties("user", ""));
  }

  if (scope === "default") {
    // We need to call our own function to "re-configure" the analytics.
    configureAnalytics(getDefaultProperties("default", ""));
  }
}

function updateMap(
  map: Map<string, unknown>,
  properties: Record<string, unknown>,
) {
  Object.entries(properties).forEach(([key, value]) => {
    if (isNil(value) || value === "") {
      map.delete(key);
    } else {
      map.set(key, value);
    }
  });
}

export function getDefaultProperties(
  scope: FirebasePropertyType,
  prefix: string = "",
) {
  return Object.fromEntries(
    Array.from(PROPERTY_MAP[scope].entries()).map(([key, value]) => [
      prefix + key,
      value,
    ]),
  );
}
