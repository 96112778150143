import type { Nullable } from "@sunrise/utils";

import { updateDefaultProperties } from "./default-properties";

/**
 * Please use this function directly instead of `setUserProperties` or `configureAnalytics`.
 */
export function setDefaultProperties(
  properties: Record<string, Nullable<string>>,
) {
  // This is here because we need to apparently send the properties for every event manually.
  updateDefaultProperties(properties, "default");
}
