import { updateDefaultProperties } from "./default-properties";
import { firebaseAnalytics } from "./firebase-analytics";

/**
 * https://firebase.google.com/docs/analytics/user-properties?platform=web
 */
export function setUserProperties(properties: Record<string, string>) {
  if (!firebaseAnalytics) {
    return;
  }

  updateDefaultProperties(properties, "user");
}
