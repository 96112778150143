import { type ReactNode } from "react";
import { useAtomValue } from "jotai";

import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import { getPrefixAndSubtitle } from "@sunrise/details";
import { nowAtom } from "@sunrise/time";
import { useTranslatable } from "@sunrise/translator";
import { type Nullable, useStableDate } from "@sunrise/utils";
import { useContinueWatchingProgress } from "@sunrise/yallo-continue-watching";
import { programIsPlayingAtTime, useEpgRelativeTime } from "@sunrise/yallo-epg";
import type { ProgramBoxProps } from "@sunrise/yallo-web-components";
import { ProgramBox } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";
import { usePlay } from "@/hooks";

type EpgItemProps = Pick<
  ProgramBoxProps,
  | "variant"
  | "channelLogo"
  | "channelName"
  | "coverImageUrl"
  | "title"
  | "subtitle"
  | "topProgramNumber"
  | "replayProgress"
  | "variant"
> & {
  epgStart: TimeISOString;
  epgEnd: TimeISOString;
  channelId: ChannelId;
  epgId: EPGEntryId;
  assetId: Nullable<AssetId>;
  forceLiveProgress?: boolean;
  seasonNumber?: Nullable<number>;
  episodeNumber?: Nullable<number>;
};

function EpgItem(props: EpgItemProps): ReactNode {
  const startDate = useStableDate(props.epgStart);
  const endDate = useStableDate(props.epgEnd);
  const now = useAtomValue(nowAtom);
  const t = useTranslatable();

  const redirectUrl =
    !props.epgId || !props.assetId
      ? null
      : route.details.root({
          epgId: props.epgId,
          assetId: props.assetId,
        });

  const { airTime, formattedEnd, formattedStart } = useEpgRelativeTime({
    startDate,
    endDate,
    expiresAt: null,
    forceLiveProgress: props.forceLiveProgress,
  });

  const {
    play,
    label: playLabel,
    icon: playIcon,
  } = usePlay({
    channelId: props.channelId,
    epgId: props.epgId,
    epgStart: props.epgStart,
    epgEnd: props.epgEnd,
  });

  const isLive = programIsPlayingAtTime(
    { startTime: startDate, endTime: endDate },
    now,
  );

  const { accessibleLabel, label } = getPrefixAndSubtitle(
    props.seasonNumber,
    props.episodeNumber,
    props.subtitle,
  );

  const { replayProgress, liveProgress } = useContinueWatchingProgress(
    startDate && endDate
      ? {
          epgId: props.epgId,
          start: startDate,
          end: endDate,
        }
      : null,
  );

  return (
    <ProgramBox
      {...props}
      accessibleSubtitle={t(accessibleLabel)}
      airTime={airTime}
      endDate={endDate}
      formattedEnd={formattedEnd}
      formattedStart={formattedStart}
      isLive={isLive}
      liveProgress={liveProgress}
      playIcon={playIcon}
      playLabel={playLabel}
      redirectUrl={redirectUrl}
      replayProgress={replayProgress}
      startDate={startDate}
      subtitle={label}
      variant={props.variant}
      onPlay={play}
    />
  );
}

export { EpgItem };
