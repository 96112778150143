import {
  lazy,
  type ReactElement,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useAtom, useAtomValue } from "jotai";

import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";
import { selectCanReplay } from "@sunrise/jwt";
import { AsPage } from "@sunrise/pages";
import {
  MemoryRouter,
  MemoryRouterProvider,
  useCreateMemoryRouter,
  useMemoryRouter,
} from "@sunrise/routing";
import { useTranslatable, useTranslator } from "@sunrise/translator";
import type { SettingsPages } from "@sunrise/yallo-settings";
import {
  feedbackEnabledAtom,
  settingsOverlayPageAtom,
  settingsV2Atom,
} from "@sunrise/yallo-settings";
import { Button, Link, SlideOut, Text } from "@sunrise/yallo-web-components";

import { ChannelGroupEditor } from "@/components/channel-list/channel-group/channel-group-editor";
import { ChannelGroupList } from "@/components/channel-list/channel-group/channel-group-list";
import { route } from "@/config/route";
import { login } from "@/core";
import { useLogout } from "@/hooks";

import styles from "./settings.module.css";

const LanguageSwitcher = lazy(
  () => import("../language-switcher/language-switcher"),
);
const SettingsProfile = lazy(() => import("./settings-profile"));
const SettingsReplay = lazy(() => import("./settings-replay"));
const SettingsTermsOfUse = lazy(() => import("./settings-terms-of-use"));

const Settings = (): ReactElement => {
  const t = useTranslatable();
  const [currentPage, setPage] = useAtom(settingsOverlayPageAtom);

  const close = useCallback(() => setPage(null), [setPage]);

  const settingRoutes = useMemo(
    () =>
      [
        {
          id: "root",
          title: { key: "menu_settings" },
          component: <BaseSettings close={close} />,
        },
        {
          id: "language-switcher",
          title: { key: "settings_language" },
          component: <LanguageSwitcher />,
        },
        {
          id: "channel-list",
          title: { key: "channel_list_title" },
          component: <ChannelGroupList />,
        },
        {
          id: "channel-group-editor",
          title: { key: "channel_list_title" },
          component: <ChannelGroupEditor />,
        },
        {
          id: "replay",
          title: { key: "settings_replay" },
          component: <SettingsReplay />,
        },
        {
          id: "profile",
          title: { key: "settings_page_profile_title" },
          component: <SettingsProfile />,
        },
        {
          id: "terms-of-use",
          title: { key: "settings_terms_of_use" },
          component: <SettingsTermsOfUse />,
        },
      ] as const satisfies {
        id: SettingsPages;
        [x: string | number | symbol]: unknown;
      }[],
    [close],
  );

  const router = useCreateMemoryRouter<SettingsPages>(
    settingRoutes,
    "root",
    settingsOverlayPageAtom,
  );

  const isRoot = router.currentRoute?.id === "root";

  return (
    <SlideOut.Root
      labelClose={t("button_close")}
      open={!!currentPage}
      title={t(router.currentRoute?.title)}
      titlePrefix={
        !isRoot && (
          <Button
            icon="arrowLeft"
            variant="text"
            hideLabel
            onClick={() => router.navigate("root")}
          >
            {t("button_go_back")}
          </Button>
        )
      }
      trigger={
        <Button icon="settings" variant="text" hideLabel>
          {t("menu_settings")}
        </Button>
      }
      onOpenChange={(nextOpen) => {
        if (!nextOpen) return router.navigate(null);
        if (nextOpen) return router.navigate("root");
      }}
    >
      <AsPage page="settings_page">
        <Suspense>
          <MemoryRouterProvider router={router}>
            <MemoryRouter />
          </MemoryRouterProvider>
        </Suspense>
      </AsPage>
    </SlideOut.Root>
  );
};

const BaseSettings = ({ close }: { close: () => void }): ReactElement => {
  const t = useTranslator();

  const feedbackEnabled = useAtomValue(feedbackEnabledAtom);
  const { navigate } = useMemoryRouter();
  const { canUserLogout, logout } = useLogout();
  const [error, setError] = useState(false);

  const settingsData = useAtomValue(settingsV2Atom);
  const language = useAtomValue(currentLanguageAtom);

  const supportUrl = getLocalizedValue(settingsData.support_url, language);
  const feedbackUrl =
    feedbackEnabled && getLocalizedValue(settingsData.feedback_url, language);
  const companyUrl = getLocalizedValue(settingsData.company_url, language);

  const canReplay = useAtomValue(selectCanReplay);

  const handleLogin = () => login();

  const handleError = () => {
    setError(true);
  };

  if (error) {
    throw new Error("Global error thrown");
  }

  return (
    <>
      <SlideOut.ScrollableList className={styles.baseList}>
        <SlideOut.Button
          icon="language"
          showMore
          onClick={() => navigate("language-switcher")}
        >
          {t("settings_language")}
        </SlideOut.Button>
        <SlideOut.Button
          icon="channelList"
          showMore
          onClick={() => navigate("channel-list")}
        >
          {t("channel_list_title")}
        </SlideOut.Button>
        {canReplay && (
          <SlideOut.Button
            icon="replayTv"
            showMore
            onClick={() => navigate("replay")}
          >
            {t("settings_replay")}
          </SlideOut.Button>
        )}
        <SlideOut.Button
          icon="login"
          showMore
          onClick={() => navigate("profile")}
        >
          {t("settings_page_profile_title")}
        </SlideOut.Button>
        <SlideOut.Button
          icon="termsOfUse"
          showMore
          onClick={() => navigate("terms-of-use")}
        >
          {t("settings_terms_of_use")}
        </SlideOut.Button>
        {supportUrl && (
          <SlideOut.Link href={supportUrl} icon="support" target="_blank">
            {t("menu_support")}
          </SlideOut.Link>
        )}
        {feedbackUrl && (
          <SlideOut.Link href={feedbackUrl} icon="feedback" target="_blank">
            {t("settings_feedback")}
          </SlideOut.Link>
        )}
        {import.meta.env.MODE !== "production" && (
          <>
            <SlideOut.Link
              href={route.devTools.root()}
              icon="login"
              onClick={close}
            >
              Dev: Login
            </SlideOut.Link>
            <SlideOut.Link
              href={route.devTools.featureFlags()}
              icon="info"
              onClick={close}
            >
              Dev: Feature Flags
            </SlideOut.Link>
            <SlideOut.Button icon="bug" onClick={handleError}>
              Dev: Trigger error page
            </SlideOut.Button>
          </>
        )}
        <div className={styles.companyInformation}>
          <Link href={companyUrl ?? ""} target="_blank" variant="none">
            <Text size="large" variant="label">
              {t("company_information")}
            </Text>
          </Link>
        </div>
      </SlideOut.ScrollableList>
      <SlideOut.Actions>
        {canUserLogout ? (
          <Button
            className={styles.authButton}
            variant="outlined"
            onClick={async () => {
              await logout();
              close();
            }}
          >
            {t("button_logout")}
          </Button>
        ) : (
          <Button className={styles.authButton} onClick={handleLogin}>
            {t("login_button")}
          </Button>
        )}
      </SlideOut.Actions>
    </>
  );
};

export { Settings };
