// eslint-disable-next-line simple-import-sort/imports
import "@/utils/enable-scan";
import { createRoot } from "react-dom/client";
import { isNil } from "lodash";

import "@sunrise/web-common-styles/base.css";
import "@sunrise/web-common-styles/reset.css";
import "@/styles/fonts.css";
import "@/styles/global.css";
import "./wdyr";

import { AppProvider } from "@/app-provider";
import { webStore } from "@/core";
import { ErrorBoundary } from "@/features/monitoring/error-boundary";
import { Root } from "@/root";
import {
  initIntegrations,
  initMonitoring,
  logVersion,
} from "@/utils/init-integrations";
import { StrictMode } from "react";

window.addEventListener("DOMContentLoaded", () => {
  logVersion();
  initMonitoring();

  const appNode = document.getElementById("app");
  if (isNil(appNode)) throw new Error("App element does not exist");

  initIntegrations();

  createRoot(appNode).render(
    <StrictMode>
      <ErrorBoundary fullPage>
        <AppProvider
          enableDevTools={!import.meta.env.PROD}
          storeInstance={webStore}
        >
          <Root />
        </AppProvider>
      </ErrorBoundary>
    </StrictMode>,
  );
});
