import clsx from "clsx";

import type { MappedDisplayType } from "@sunrise/yallo-recommendations";
import { ProgramBoxSkeleton } from "@sunrise/yallo-web-components";

import styles from "./scrollable-row.skeleton.module.css";

const programBoxSize = window
  .getComputedStyle(document.body)
  .getPropertyValue("--program-box-size");
const size = parseInt(programBoxSize, 10);

export function ScrollableRowSkeleton({
  variant,
}: {
  variant?: MappedDisplayType;
}) {
  return (
    <div className={clsx(styles.row, styles[variant ?? ""])}>
      {[...new Array(Math.max(1, Math.ceil(window.innerWidth / size)))].map(
        (_, index) => (
          <ProgramBoxSkeleton key={index} variant={variant} />
        ),
      )}
    </div>
  );
}
