import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";

import { Text } from "../text";
import styles from "./recording-delete-warning.module.css";

type RecordingDeleteWarningProps = {
  expiresIn?: Nullable<number>; // days
  label: Nullable<string>;
} & Pick<CommonProps, "className">;

export const RecordingDeleteWarning = ({
  expiresIn,
  className,
  label,
}: RecordingDeleteWarningProps) => {
  const expiresSoon = expiresIn != null && expiresIn <= 10;
  if (!expiresSoon) return;

  return (
    <Text
      className={clsx(styles.text, className)}
      size="small"
      variant="label"
      underline
    >
      {label}
    </Text>
  );
};
